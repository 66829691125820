export const RAFFLES_PATH = {
  root: '/raffles',
  list: '/raffles/list',
  edit: '/raffles/edit/:id',
  create: '/raffles/create',
};

export const TREES_PATH = {
  root: '/trees',
  list: '/trees/list',
  edit: '/trees/edit/:id',
  create: '/trees/create',
};

export const PARTNERS_PATH = {
  root: '/partners',
  list: '/partners/list',
  edit: '/partners/edit/:id',
  create: '/partners/create',
};
